
import { defineComponent, onMounted, computed, ref } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SubmitButton from "@/components/SubmitButton.vue";

export default defineComponent({
  name: "AdminProfessionCreateModal",
  components: { SubmitButton },
  setup() {
    const store = useStore();
    const router = useRouter();

    let isLoading = computed(() => {
      return store.getters[Modules.MASTER + "getIsLoading"];
    });

    const modalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLButtonElement | null>(null);

    const schema = yup.object({
      name: yup.string().required().max(64).label("Profession Name"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        name: "",
      },
      validationSchema: schema,
    });

    const { value: name } = useField("name");

    const onSubmit = handleSubmit((values) => {
      return new Promise<void>((resolve) => {
        store
          .dispatch(Modules.MASTER + Actions.STORE_PROFESSION, values)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.MASTER + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              hideModal(modalRef.value);
              store.dispatch(Modules.MASTER + Actions.FETCH_PROFESSIONS, {
                search: "",
                url: "",
              });
            });
          })
          .catch(() => {
            setErrors(store.getters[Modules.MASTER + "getErrors"]);
            Swal.fire({
              text: "Failed to create the Profession. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .finally(() => {
            resolve();
          });
      });
    });

    return {
      isLoading,
      name,

      modalRef,
      submitButton,

      meta,
      errors,
      onSubmit,
      isSubmitting,
    };
  },
});
